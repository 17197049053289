import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { Box } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import IconArrowLeft from "@assets/svg/arrow-left.svg";
import IconArrowRight from "@assets/svg/arrow-right.svg";

import { LogoCarouselProps } from "./types";
import * as Styled from "./styles";

const LogoCarousel: React.FC<LogoCarouselProps> = ({
  items,
  title
}) => {
  const hasMinMobileSlides = items.length > 2;
  const hasMinTabletSlides = items.length > 4;
  const hasMinDesktopSlides = items.length > 6;

  const [viewportRef, embla] = useEmblaCarousel({
    loop: hasMinMobileSlides,
    skipSnaps: false,
    slidesToScroll: 1,
    align: "start",
    active: hasMinMobileSlides,
    breakpoints: {
      [media.mobile]: {
        active: hasMinTabletSlides,
        loop: hasMinTabletSlides
      },
      [media.tablet]: {
        active: hasMinDesktopSlides,
        loop: hasMinDesktopSlides
      }
    }
  });

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(false);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);

  const scrollPrev = useCallback(
    () => embla && embla.scrollPrev(),
    [embla]
  );
  const scrollNext = useCallback(
    () => embla && embla.scrollNext(),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnDisabled(!embla.canScrollPrev());
    setNextBtnDisabled(!embla.canScrollNext());
  }, [embla]);

  const shouldDisplayButton = [
    hasMinMobileSlides ? "block" : "none",
    hasMinTabletSlides ? "block" : "none",
    hasMinDesktopSlides ? "block" : "none"
  ];

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
    embla.on("reInit", onSelect);
  }, [embla, onSelect]);

  const carousel = (
    <Styled.Carousel>
      <Box display={shouldDisplayButton}>
        <Styled.Button
          type="button"
          onClick={scrollPrev}
          disabled={prevBtnDisabled}
          aria-label="Go to previous slide"
        >
          <IconArrowLeft />
        </Styled.Button>
      </Box>
      <Styled.ContentWrapper ref={viewportRef}>
        <Styled.Content>
          {items.map(({ key, ...item }) => (
            <Styled.Slide {...{ key }}>
              {/* below width and height is statically set as this items are only logos displayed in the same way across all devices  */}
              <Styled.Logo {...item} width={128} height={50} />
            </Styled.Slide>
          ))}
        </Styled.Content>
      </Styled.ContentWrapper>
      <Box display={shouldDisplayButton}>
        <Styled.Button
          type="button"
          onClick={scrollNext}
          disabled={nextBtnDisabled}
          aria-label="Go to next slide"
        >
          <IconArrowRight />
        </Styled.Button>
      </Box>
    </Styled.Carousel>
  );

  return title ? (
    <Styled.Wrapper>
      <Styled.TitleWrapper>
        {typeof title === "string" ? (
          <Typography variant="body9" as="h3" fontFamily="heading">
            {title}
          </Typography>
        ) : (
          title
        )}
      </Styled.TitleWrapper>
      {carousel}
    </Styled.Wrapper>
  ) : (
    carousel
  );
};

export default LogoCarousel;
