import React from "react";
import { storyblokEditable } from "@storyblok/react";

import LogoCarousel from "@components/molecules/LogoCarousel";
import { getImage } from "@utils/getImage";
import RichText from "@components/atoms/RichText";
import { LogoCarouselItem } from "@components/molecules/LogoCarousel/types";

import { LogoCarouselStoryblokProps } from "./types";

const LogoCarouselStoryblok: React.FC<LogoCarouselStoryblokProps> = ({
  blok
}) => {
  const { items, heading } = blok;

  const carouselItems = items.reduce<LogoCarouselItem[]>(
    (result, storyblokImage) => {
      const image = getImage(storyblokImage);

      if (!image) {
        return result;
      }

      return [
        ...result,
        { ...image, key: storyblokImage.id.toString() || Date.now() }
      ];
    },
    []
  );

  return (
    <LogoCarousel
      items={carouselItems}
      title={
        heading?.[0]?.text?.content[0].content?.length ? (
          <RichText
            content={heading?.[0]?.text}
            mobileTextAlignment={heading?.[0].mobileTextAlignment}
            tabletTextAlignment={heading?.[0].tabletTextAlignment}
            desktopTextAlignment={heading?.[0].desktopTextAlignment}
            underlineType={heading?.[0].underlineType}
          />
        ) : undefined
      }
      {...storyblokEditable(blok)}
    />
  );
};

export default LogoCarouselStoryblok;
