import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import Image from "@components/atoms/Image";

export const Wrapper = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 3.3125rem;
  text-align: center;
  @media ${media.mobile} {
    margin-bottom: 4.375rem;
    text-align: left;
  }
  @media ${media.tablet} {
    width: calc(50% - 2 * 6rem);
    margin: 0 auto 3.125rem;
  }
`;

export const Carousel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media ${media.mobile} {
    flex-wrap: nowrap;
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
  &.is-dragging {
    cursor: grabbing;
  }
  order: -1;
  margin-bottom: 3rem;
  @media ${media.mobile} {
    margin: 0 2rem;
    order: 0;
  }
  @media ${media.tablet} {
    margin: 0 5rem;
  }
`;

export const Content = styled.ul`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -1rem;

  @media ${media.mobile} {
    margin-left: -3.625rem;
  }
`;

export const Slide = styled.li`
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  padding-left: 1rem;

  @media ${media.mobile} {
    min-width: 25%;
    padding-left: 3.625rem;
  }
  @media ${media.tablet} {
    min-width: calc(100% / 6);
  }
`;

export const Logo = styled(Image)`
  max-height: 3.125rem;
  width: auto;
  filter: grayscale(100%);
  transform: translate3d(0, 0, 0);
`;

export const Button = styled.button`
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.625rem;
  color: inherit;
  svg {
    height: 1em;
    width: 1.3em;
    font-size: 0.9375rem;
    fill: currentColor;
  }
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
  @media ${media.mobile} {
    padding: 0;
  }
  @media ${media.tablet} {
    transition: ${({ theme }) => theme.transition};
    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;
